import React from "react";
import { LongNavBar, ShortNavBar } from "./TopNavBar";
import CoverImage from  '../images/Group_top_cover.png';

 const Home = ()=>
{
    return(
        <div className='h-fit w-full relative '>
        <img src={CoverImage} className='h-64 md:h-fit w-screen'/>
        <LongNavBar  />
        <ShortNavBar/>

        </div>
    );
}

export default Home;