import React, { useEffect } from "react";

import CoverImage from '../images/Coverimage2.jpg';
import Aos from "aos";
import Lottie from 'lottie-react';
import MarketingGif from '../images/MarketingAnim.json';
import backgroundPurple from '../images/background.jpg';
import { useTranslation } from "react-i18next";

const AboutUs = ()=>
{
    const PurpleColor = "#9A549F";
    const {t} = useTranslation(["AboutUs"]);
    const Lng = localStorage.getItem("i18nextLng");

    useEffect(()=>{

            Aos.init();

    } ,[]);

    return(

    <div className="flex flex-row h-fit   place-items-center  justify-center w-full mt-4   " style={{direction:Lng == "en"?"ltr":"rtl"}}    > 


        <div className="grid md:grid-cols-2 ">

            
        <div className="flex flex-col  rounded-xl w-fit gap-4 p-5 place-items-center ml-3 ">
            
            <div className="flex flex-col gap-1 place-items-start justify-start h-1/2 w-full mb-3  ">
                <label className="w-full  text-xl md:text-4xl font-bold mb-2" style={{color:PurpleColor}} data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000">{t("title")}</label>
                <label className="w-full  text-md md:text-xl font-bold ">{t("p1")}</label>
                <label className="w-full  text-md md:text-xl font-bold " >{t("p2")}</label>
                <label className="w-full  text-md md:text-xl font-bold ">{t("p3")}</label>
                <label className="w-full  text-md md:text-xl font-bold ">{t("p4")}</label>
    
            </div>


            <div className="flex w-3/4 place-items-start justify-start sm:hidden">
            <Lottie animationData={MarketingGif} size={20}/>
            </div>
    
 
            <div className="flex flex-col flex-wrap gap-1 place-items-center justify-center  w-full  ">
    
                <ul className=" list-disc list-inside "   >
                <li className=" w-full text-md md:text-md " style={{color:PurpleColor}}>{t("li1")}</li>
                <li className=" w-full text-md md:text-md " style={{color:PurpleColor}}>{t("li2")}</li>
                <li className=" w-full text-md md:text-md " style={{color:PurpleColor}}>{t("li3")}</li>
                <li className=" w-full text-md md:text-md " style={{color:PurpleColor}}>{t("li4")}</li>
                <li className=" w-full text-md md:text-md " style={{color:PurpleColor}}>{t("li5")}</li>
                </ul>
                
            </div>
    
            </div>

            <div className=" w-3/4 place-items-start justify-start hidden md:flex">
            <Lottie animationData={MarketingGif} size={20}/>
            </div>
    

        </div>


    </div>
    );
}

export default AboutUs;