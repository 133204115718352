import React from "react";
import PopMessageImg from '../images/Popupmesssage.png';
import Cloud1 from '../images/cloud.png1.png';
import Cloud2 from '../images/cloud.png2.png';
import Rocket2 from '../images/Rocket2.png';
import { useEffect } from 'react';
import Aos from 'aos';
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";


const MessageBox = ()=>
{
  const {t} = useTranslation(["MessageBox"]);
    
  useEffect(()=>{

    Aos.init();
  
  } ,[]);

    return(

        <div className="flex  md:h-fit md:p-8  h-screen justify-center place-items-center w-full" >

          
        <div className='flex  rounded-lg w-fit h-fit relative place-items-center justify-center ' >
  
            <div className='flex place-items-center w-3/5 sm:w-4/6'  >
  
              <div className='relative  ' data-aos="zoom-in"  datq-aos-anchor-placement="bottom-bottom" data-aos-delay="" data-aos-duration="1000">
                <img src={PopMessageImg} className='w-full'/>
                <div className='flex flex-wrap flex-col  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pb-3  place-items-center  '>
                <label className='text-white text-sm sm:text-2xl text-center sm:w-72 w-48 '>{t("desc")}</label>

                <div className="w-fit h-fit">
                <Link to="ConnectUs" smooth={true} className="h-fit w-fit">
                  <button className='btn  btn-sm btn-active btn-ghost text-white rounded-full mt-3'>{t("button")}</button>
                </Link>
                </div>
              </div>
                </div>


                <div className=' absolute top-2  sm:top-1 -right-0 lg:-right-10 w-1/4  sm:w-2/6  ' data-aos="fade-down"  data-aos-delay="300" data-aos-duration="1000"><img src={Cloud1}/></div>

  
                <div className='absolute sm:-bottom-20 -bottom-10 -left-12 sm:-left-40 sm:w-5/6 w-4/6'  data-aos="fade-up"  datq-aos-anchor-placement="bottom-bottom" data-aos-delay="400" data-aos-duration="1000"  ><img src={Rocket2}/>
              <div className=' absolute bottom-20  left-12 sm:bottom-32 sm:left-20 w-1/4' data-aos="fade-right" data-aos-delay="400" data-aos-duration="1000"><img src={Cloud2}/></div>
              </div>

  
              </div>
        
          </div>
  
        </div>
    );

}

export default MessageBox ;