import logo from './logo.svg';
import './App.css';
import CoverImage from  './images/Group_top_cover.png'
import { LongNavBar } from './components/TopNavBar';
import ContactUs from './components/Aboutus';
import Home from './components/Home';
import AboutUs from './components/Aboutus';
import MessageBox from './components/MessageBox';
import Services from './components/Services';
import Blogs from './components/Blogs';
import Features from './components/Features';
import Clients from './components/Clients';
import Team from './components/Team';
import FeedBack from './components/Feedback';
import ConnectUs from './components/ConnectUs';
import Footer from './components/footer';
import { Element ,Link} from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Packages from './components/Packages';



function App() {



  return (
    <div className='flex flex-col w-full h-fit relative overflow-x-none'>

      <Element name="Home">
      <Home/>
      </Element>



      <Element name="AboutUs">
      <AboutUs />
      </Element>

      <Element name="Services">
      <Services />
      </Element>

      {/* 
      <Element name="Blogs">
      <Blogs />
      </Element>
      */}

      <MessageBox />


      <Element name="Features">
      <Features />
      </Element>

      <Element name="Packages">
      <Packages />
      </Element>

      {/*
      <Clients/>

      <Element name='Team'>
      <Team/>
      </Element>

      <Element name="FeedBack">
      <FeedBack />
      </Element>
    */}


      <Element name='ConnectUs'>
      <ConnectUs />
      </Element>

      
      <Link className="text-white  bottom-9 right-4 fixed " to="Home"  smooth={true}>
        <FontAwesomeIcon 
         className='p-3 rounded-full shadow-2xl' 
         icon={faChevronUp} size='xl' 
         style={{backgroundColor:"purple"}}/>
        </Link>


    </div>
  );
}

export default App;
