import { faFacebookF, faInstagram, faLinkedin, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faLink, faLocation, faLocationPin, faMinus, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import axios from "axios";
import $ from 'jquery';
import { useTranslation } from "react-i18next";


const ConnectUs = () =>
{
    const [Name,setName] = useState("");
    const [PhoneNumber,setPhoneNumber] = useState("");
    const [Email,setEmail] = useState("");
    const {t} = useTranslation(["ContactUs"]);
    const Lng = localStorage.getItem("i18nextLng");

    const handleSubmitClick = ()=>
    {
        if(Name !='' && PhoneNumber !='' && Email !='')
        {
        

            let data = {};
            data.name = Name;
            data.phone_number = PhoneNumber;
            data.email = Email;

            
            axios.post("https://marymubs.com/php/mail.php",data)
            .then(Response => {
                //console.log(data);
                //console.log(Response.data);
                alert("Mail sent successfully!");
            })
            .catch(Error => {
                console.log(Error);
            });

            

        {/*
            $.ajax({
                type:'POST',
                url:  "http://localhost/marrys/public/php/test.php",
                datatype:"text",
                async:false,
                data:{'data': JSON.stringify(data)},
                success:function(response){
    
                    console.log(response);
         
                }
    
            });

        */}
    


            setName('');
            setPhoneNumber('');
            setEmail('');
        }
        else
        {
            alert("All feilds are required!!");
        }

    }


    return( 

        <div className="flex flex-col gap-2 bg-neutral place-items-center justify-center w-full p-4 ">

        <div className="flex  mt-1 mb-3">
            <label className="text-white text-3xl font-bold" style={{color:"white"}}><FontAwesomeIcon icon={faMinus} color="purple" />{t("title1")}</label>
            <label className="text-white text-3xl font-bold" style={{color:"purple"}}>{t("title2")}<FontAwesomeIcon icon={faMinus} color="purple" /></label>
        </div>

        <div className="flex flex-col mb-4">
             <p className="text-white text-sm font-semibold text-center">
                {t("desc")}
             </p>
         </div>


         <div  className="grid md:grid-cols-2 gap-2 " style={{direction:Lng =="en"?"ltr":"rtl"}}>

            {/*Left Side panel*/}
            <div className="flex flex-col gap-6">

            {/*  */}
            <div className="flex flex-col gap-1">
            <div className="flex flex-col w-fit">
            <p className="font-semibold text-white text-xl">{t("contactus-title")}</p>
            <div className=" h-0 outline-1 outline" style={{color:"purple"}}></div>
            </div>

            <p className="font-md text-white w-2/3">
            {t("contactus-desc")}
            </p>
            </div>


            {/*  */}
            <div className="flex flex-col gap-1">
            <div className="flex flex-col w-fit">
            <p className="font-semibold text-white text-xl">{t("location-title")}</p>
            <div className=" h-0 outline-1 outline" style={{color:"purple"}}></div>
            </div>

            <div className="flex flex-row gap-2 place-items-center">
                <FontAwesomeIcon icon={faPhone} color="white" />                
                    <a className="text-white" href="tel:+972547580672">0547580672</a>
                 </div>

                 <div className="flex flex-row gap-2 place-items-center">
                <FontAwesomeIcon icon={faLocationPin} color="white" />
                <label className="text-white">{t("location-address")}</label>
                 </div>


                 
                <div className="flex flex-row gap-4 pl-2 pt-4 pb-4">

                    
                <a href='https://business.facebook.com/MaryMubss'> <FontAwesomeIcon icon={faFacebookF} color="white" size="2xl"/></a> 
                <a href='https://www.instagram.com/marymubss/'> <FontAwesomeIcon icon={faInstagram} color="white" size="2xl"/></a>  
                <a href=''> <FontAwesomeIcon icon={faTiktok} color="white" size="2xl"/></a>  
                <a href=''> <FontAwesomeIcon icon={faLinkedin} color="white" size="2xl"/></a>     
                <a  href='mailto:info@marymubs.com'> <FontAwesomeIcon icon={faEnvelope} color="white" size="2xl"/></a>     

                </div>


            </div>
            </div>

            {/*Right Side panel*/}


            <div className="flex flex-col place-items-center pb-5">

            <div className="flex flex-row gap-1">
            <div className="flex flex-col w-fit">
            <label className=" text-white text-md">{t("form-desc")}</label>
            <div className=" h-0 outline-1 outline" style={{color:"purple"}}></div>
            </div>
            </div>

            {/* form */}
            <div className="flex flex-col mt-6 gap-3 w-2/3">

            <div className="flex flex-col ">
            <label className="text-white text-center">{t("form-input1")}</label>
            <input type="text" placeholder="" className="input input-sm w-full text-white  " style={{backgroundColor:"purple"}} value={Name} onChange={(e)=>setName(e.target.value)} />
            </div>

            <div className="flex flex-col">
            <label className="text-white text-center">{t("form-input2")}</label>
            <input type="number" inputMode="numeric" placeholder="" className="input input-sm w-full text-white " style={{backgroundColor:"purple"}} value={PhoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} />
            </div>


            <div className="flex flex-col">
            <label className="text-white text-center">{t("form-input3")}</label>
            <input type="email" placeholder="" className="input input-sm w-full text-white " style={{backgroundColor:"purple"}} value={Email} onChange={(e)=>setEmail(e.target.value)}/>
            </div>

            <button className="btn btn-ghost btn-sm text-white rounded-full outline-1 outline-dashed outline-white" onClick={handleSubmitClick}>{t("form-button")}</button>

            </div>

            </div>

         </div>

        </div>
    );
}

export default ConnectUs;