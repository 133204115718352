import { faHeart, faMinus, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Aos from "aos";
import GoldStar from '../images/icons/goldstart.png';
import BronzeStart from '../images/icons/bronzestar.png';
import SilverStart from '../images/icons/silverstar.png';
import { useTranslation } from "react-i18next";

const Packages = () =>
{
    const PurpleColor = "#9A549F";
    const {t} = useTranslation(["Packages"]);
    const Lng = localStorage.getItem("i18nextLng");

    useEffect(()=>{

        Aos.init();

        } ,[]);


    return(

        <div className="flex flex-col gap-2  place-items-center justify-center w-full p-4 " style={{direction:Lng=="en"?"ltr":"rtl"}}>

        <div className="flex gap-2 mt-1 mb-3">
            <label className="text-white text-3xl font-bold" style={{color:PurpleColor}}><FontAwesomeIcon icon={faMinus} color="purple" />{t("title")}<FontAwesomeIcon icon={faMinus} color="purple" /></label>
        </div>

        <div className="grid md:grid-cols-3 gap-8 place-items-center justify-center p-5">

            {/* Gold Tag */}
            <div className="flex flex-col place-items-center p-3 gap-2 shadow-xl" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000" style={{height:280,width:230, backgroundColor:"purple",borderBottomRightRadius:80,borderTopLeftRadius:80}} >
                <div className="flex w-full h-10 relative place-items-center ">
                <div className="flex rounded-full bg-white w-20 h-20 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2  place-items-center justify-center">
                <img  src={GoldStar} className="w-12 h-12"/>
                </div>
                </div>

                <p className="text-xs text-center text-white font-semibold">{t("p1")}</p>
                <p className="text-xs text-center text-white font-semibold">{t("p2")}</p>
                <p className="text-xs text-center text-white font-semibold">{t("p3")}</p>

                <div className="flex place-items-center mt-3 ">
                <ul className="list-disc list-inside">
                <li className=" w-full text-xs text-white text-start pl-3 font-semibold mb-2">{t("li1")}</li>
                <li className=" w-full text-xs text-white pl-3 font-semibold mb-2" >{t("li2")}</li>
                <li className=" w-full text-xs text-white pl-3 font-semibold mb-2" >{t("li3-gold")}</li>
                <li className=" w-full text-xs text-white pl-3 font-semibold mb-2" >{t("li4")}</li>
                </ul>
                </div>
            </div>


            
            {/* Siver Tag */}
            <div className="flex flex-col place-items-center p-3 gap-2 shadow-xl" data-aos="flip-up" data-aos-delay="300" data-aos-duration="1000" style={{height:280,width:230, backgroundColor:"purple",borderBottomRightRadius:80,borderTopLeftRadius:80}} >
                <div className="flex w-full h-10 relative place-items-center ">
                <div className="flex rounded-full bg-white w-20 h-20 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2  place-items-center justify-center">
                <img  src={SilverStart} className="w-12 h-12"/>
                </div>
                </div>
                <p className="text-xs text-center text-white font-semibold">{t("p1")}</p>
                <p className="text-xs text-center text-white font-semibold">{t("p2")}</p>
                <p className="text-xs text-center text-white font-semibold">{t("p3")}</p>

                <div className="flex place-items-center mt-3 ">
                <ul className="list-disc list-inside">
                <li className=" w-full text-xs text-white text-start pl-3 font-semibold mb-2">{t("li1")}</li>
                <li className=" w-full text-xs text-white pl-3 font-semibold mb-2" >{t("li2")}</li>
                <li className=" w-full text-xs text-white pl-3 font-semibold mb-2" >{t("li3-silver")}</li>
                <li className=" w-full text-xs text-white pl-3 font-semibold mb-2" >{t("li4")}</li>
                </ul>
                </div>
            </div>


            
            {/* Bronze Tag */}
            <div className="flex flex-col place-items-center p-3 gap-2 shadow-xl" data-aos="flip-right" data-aos-delay="300" data-aos-duration="1000" style={{height:280,width:230, backgroundColor:"purple",borderBottomRightRadius:80,borderTopLeftRadius:80}} >
                <div className="flex w-full h-10 relative place-items-center ">
                <div className="flex rounded-full bg-white w-20 h-20 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2  place-items-center justify-center">
                    <img  src={BronzeStart} className="w-12 h-12"/>
                </div>
                </div>

                <p className="text-xs text-center text-white font-semibold">{t("p1")}</p>
                <p className="text-xs text-center text-white font-semibold">{t("p2")}</p>
                <p className="text-xs text-center text-white font-semibold">{t("p3")}</p>

                <div className="flex place-items-center mt-3 ">
                <ul className="list-disc list-inside">
                <li className=" w-full text-xs text-white text-start pl-3 font-semibold mb-2">{t("li1")}</li>
                <li className=" w-full text-xs text-white pl-3 font-semibold mb-2" >{t("li2")}</li>
                <li className=" w-full text-xs text-white pl-3 font-semibold mb-2" >{t("li3-bronze")}</li>
                <li className=" w-full text-xs text-white pl-3 font-semibold mb-2" >{t("li4")}</li>
                </ul>
                </div>
            </div>

        </div>


        </div>

    );

}

export default Packages;