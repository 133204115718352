import { faBars, faBarsStaggered, faLocationPinLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import MainIcon  from '../images/MarryIcon.png';

import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';

export const LongNavBar = ()=>
{
    const {i18n,t} = useTranslation(["NavBar"]);

    useEffect(()=>{

        if(localStorage.getItem("i18nextLng")?.length>2)
        {
            i18next.changeLanguage("en");
        }


    },[]);

    const handleLanguageChange = (e)=>
    {
            i18n.changeLanguage(e.target.value);

    }

    return(
        <div className=" w-full h-fit absolute top-0 hidden lg:flex p-2">
            
            <div className="flex flex-row place-items-center justify-start w-full pl-4 pt-2 ">
                <img src={MainIcon} className="w-fit h-10 "/>
            </div>
            
            <div className="flex flex-row place-items-center justify-end w-full">
                <Link className="text-white" to="Home"  smooth={true}><button className="btn btn-ghost btn-sm " >{t("home")}</button></Link>
                <Link className="text-white" to="Features"  smooth={true}><button className="btn btn-ghost btn-sm " >{t("features")}</button></Link>
                <Link className="text-white" to="Services"  smooth={true}><button className="btn btn-ghost btn-sm " >{t("services")}</button></Link>
                <Link className="text-white" to="Packages"  smooth={true}><button className="btn btn-ghost btn-sm " >{t("packages")}</button></Link>
                {/*                 
                <Link className="text-white" to="Blogs"  smooth={true}><button className="btn btn-ghost btn-sm " >Blogs</button></Link>
                <Link className="text-white" to="Team"  smooth={true}><button className="btn btn-ghost btn-sm " >Team</button></Link>
                <Link className="text-white" to="FeedBack"  smooth={true}><button className="btn btn-ghost btn-sm " >FeedBack</button></Link>
                */}

                <Link className="text-white" to="ConnectUs"  smooth={true}><button className="btn btn-ghost btn-sm " >{t("contactus")}</button></Link>
                <select 
                className="select  w-fit select-xs select-ghost outline-1 outline-white outline mr-1 text-white gap-1"
                value={localStorage.getItem("i18nextLng")}
                onChange={handleLanguageChange}
                >
                <option className="font-semibold" value="en">English</option>
                <option value="ar">العربية</option>
                <option value="hb">עברית</option>
                </select>

            </div>
        </div>
    );
}


export const ShortNavBar = ()=>
{
    const [ToggleDrawer, setToggleDrawer] = useState(false);

    const {i18n,t} = useTranslation();

    useEffect(()=>{

        if(localStorage.getItem("i18nextLng")?.length>2)
        {
            i18next.changeLanguage("en");
        }


    },[]);

    const handleLanguageChange = (e)=>
    {
            i18n.changeLanguage(e.target.value);

    }

    const SideDrawer = () =>
    {
        return(
                <div className="drawer drawer-end   z-10">
                <input id="my-drawer-4" type="checkbox" className="drawer-toggle" checked={ToggleDrawer} />
                <div className="drawer-side">
                    <label htmlFor="my-drawer-4" className="drawer-overlay"></label>
                    <ul className="menu p-1 w-60 h-full bg-base-200 text-base-content rounded-lg">
                    {/* Sidebar content here */}
                    <li><div className="flex justify-end"><button className="btn text-lg font-bold" onClick={() => setToggleDrawer(false)}>X</button> </div></li>
                      
                       <li className="place-items-center"><Link to="Home"  smooth={true}><button className="btn btn-ghost btn-sm " onClick={()=>setToggleDrawer(false)}>{t("home")}</button></Link></li>
                       <li className="place-items-center"><Link to="Features"  smooth={true}><button className="btn btn-ghost btn-sm " onClick={()=>setToggleDrawer(false)}>{t("features")}</button></Link></li>
                       <li className="place-items-center"><Link to="AboutUs"  smooth={true}><button className="btn btn-ghost btn-sm " onClick={()=>setToggleDrawer(false)}>{t("aboutus")}</button></Link></li>
                       <li className="place-items-center"><Link to="Services"  smooth={true}><button className="btn btn-ghost btn-sm " onClick={()=>setToggleDrawer(false)}>{t("services")}</button></Link></li>
                       <li className="place-items-center"><Link to="Packages"  smooth={true}><button className="btn btn-ghost btn-sm " onClick={()=>setToggleDrawer(false)}>{t("packages")}</button></Link></li>
                       {/*                        
                       <li className="place-items-center"><Link to="Blogs"  smooth={true}><button className="btn btn-ghost btn-sm " onClick={()=>setToggleDrawer(false)}>Blogs</button></Link></li>
                       <li className="place-items-center"><Link to="Team"  smooth={true}><button className="btn btn-ghost btn-sm " onClick={()=>setToggleDrawer(false)}>Team</button></Link></li>
                       <li className="place-items-center"><Link to="FeedBack"  smooth={true}><button className="btn btn-ghost btn-sm " onClick={()=>setToggleDrawer(false)}>Feedback</button></Link></li>
                       */}

                       <li className="place-items-center"><Link to="ConnectUs"  smooth={true}><button className="btn btn-ghost btn-sm " onClick={()=>setToggleDrawer(false)}>{t("contactus")}</button></Link></li>


                    </ul>
                </div>
                </div>
        );
    }

    return(
        <div className="flex  w-full h-fit absolute top-0   md:visible lg:invisible ">
            
            
            <div className="flex flex-row place-items-center justify-start  pl-4   w-1/2 pt-2">
                <img src={MainIcon} className="w-20 h-10"/>
            </div>


            
            <div className="flex flex-row place-items-center justify-end w-1/2 ">

            {SideDrawer()}
            <div className="justify-center h-fit place-items-center ">
            <select 
            className="select  w-fit select-xs select-ghost outline-1 outline-white outline mr-1 text-white gap-1"
            value={localStorage.getItem("i18nextLng")}
            onChange={handleLanguageChange}
            >
                <option className="font-semibold" value="en">English</option>
                <option  className="font-semibold" value="ar">العربية</option>
                <option value="hb">עברית</option>
                </select>
                </div>
                <button className="btn btn-ghost text-white" onClick={()=>setToggleDrawer(true)}><FontAwesomeIcon icon={faBarsStaggered} size="xl"/></button>
            </div>

        </div>
    );
}