import { faBars, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Aos from "aos";
import SearchIcon from '../images/icons/magnifying-glass.png';
import WritingIcon from '../images/icons/writing.png';
import HashtagIcon from '../images/icons/hashtag.png';
import CreateIcon from '../images/icons/103414.png';
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

const Features = ()=>
{
    const PurpleColor = "#9A549F";
    const {t} = useTranslation(["Features"]);

    useEffect(()=>{

        Aos.init();

        } ,[]);

    return (

        <div className="flex flex-col">
        <div className="flex flex-col gap-2 bg-neutral place-items-center justify-center w-full p-4">

        <div className="flex gap-2 mt-1 mb-1">
            <label className="text-white text-lg sm:text-3xl font-bold"><FontAwesomeIcon icon={faMinus} color="white" />  {t("title1")}</label>
            <label className="text-white  text-lg sm:text-3xl font-bold" style={{color:PurpleColor}}>{t("title2")}<FontAwesomeIcon icon={faMinus} color="white" />  </label>
        </div>

        <div className="flex flex-col">
            <p className="text-white text-sm font-semibold text-center w-64">
            {t("desc")}
            </p>
        </div>


        <div className="grid md:grid-cols-4 gap-3 place-items-center justify-center mt-3">

        <div className="flex flex-col place-items-center justify-center gap-1" data-aos="fade-down" data-aos-delay="200" data-aos-duration="1000">
        <div className="flex rounded-full outline-dashed outline-white  outline-1 w-20 h-20 place-items-center justify-center">
        <img  src={WritingIcon} className="w-10 h-10" />
        </div>

        <label className="font-bold text-md " style={{color:PurpleColor}}>{t("write-title")}</label>
        <p className="w-64 text-white text-xs text-center">{t("write-desc")}</p>
        </div>


        <div className="flex flex-col place-items-center justify-center gap-1" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000">
        <div className="flex rounded-full outline-dashed outline-white  outline-1 w-20 h-20 place-items-center justify-center">
        <img  src={SearchIcon} className="w-10 h-10" />
        </div>

        <label className="font-bold text-md " style={{color:PurpleColor}}>{t("search-title")}</label>
        <p className="w-64 text-white text-xs text-center">{t("search-desc")}</p>
        </div>

        <div className="flex flex-col place-items-center justify-center gap-1" data-aos="zoom-out" data-aos-delay="200" data-aos-duration="1000">
        <div className="flex rounded-full outline-dashed outline-white  outline-1 w-20 h-20 place-items-center justify-center">
        <img  src={HashtagIcon} className="w-10 h-10" />
        </div>

        <label className="font-bold text-md " style={{color:PurpleColor}}>{t("speak-title")}</label>
        <p className="w-64 text-white text-xs text-center">{t("speak-desc")}</p>
        </div>

        <div className="flex flex-col place-items-center justify-center gap-1" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
        <div className="flex rounded-full outline-dashed outline-white  outline-1 w-20 h-20 place-items-center justify-center">
        <img  src={CreateIcon} className="w-10 h-10" />
        </div>

        <label className="font-bold text-md " style={{color:PurpleColor}}>{t("create-title")}</label>
        <p className="w-64 text-white text-xs text-center">{t("create-desc")}</p>
        </div>


        </div>




        </div>


        <div className="grid grid-cols-2 w-full h-20 place-items-center justify-center p-1" style={{backgroundColor:"purple"}}>
        <label className="text-white font-semibold text-center">{t("bottom-nav-desc")}</label>
        <div className="w-fit h-fit">
            <Link to="ConnectUs" smooth={true} className="h-fit w-fit">
            <button className="btn btn-ghost outline-1 outline-dashed  btn-sm pl-3 pr-3  outline-white text-white">{t("bottom-nav-button")}</button>
            </Link>
        </div>


        </div>
        </div>

    );

}

export default Features;