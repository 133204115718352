import { faBars, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Aos from "aos";
import GraphicDesignIcon from '../images/icons/graphic-design.png';
import SoftwareSolutionsIcon from '../images/icons/automated.png';
import DigitalMarketingIcon from '../images/icons/promotion.png';
import AnimationIcon from '../images/icons/animate.png';
import SocialMediaAccountsIcon from '../images/icons/population.png';
import MarketingModels from '../images/icons/model.png';
import { useTranslation } from "react-i18next";

const Services = ()=>
{
    const PurpleColor = "#9A549F";
    const {t} = useTranslation(["Services"]);

    useEffect(()=>{

        Aos.init();

        } ,[]);

    return (

        <div className="flex flex-col gap-2 bg-neutral place-items-center justify-center w-full p-6   ">

        <div className="flex gap-2 mt-1 mb-3">
            <label className="text-white text-3xl font-bold"><FontAwesomeIcon icon={faMinus} color="white" />{t("title1")}</label>
            <label className="text-white text-3xl font-bold" style={{color:PurpleColor}}>{t("title2")} <FontAwesomeIcon icon={faMinus} color="white" />  </label>
        </div>


        <div className="grid md:grid-cols-3 gap-3 place-items-center justify-center">

        <div className="flex flex-col place-items-center justify-center gap-1" data-aos="fade-down" data-aos-delay="200" data-aos-duration="1000">
        <div className="flex rounded-full outline-dashed outline-white  outline-1 w-20 h-20 place-items-center justify-center">
            <img  src={GraphicDesignIcon} className="w-10 h-10" />
        </div>

        <label className="font-bold text-md " style={{color:PurpleColor}}>{t("graphic-design-title")}</label>
        <p className="w-64 text-white text-xs text-center">{t("graphic-design-desc")}</p>
        </div>


        <div className="flex flex-col place-items-center justify-center gap-1 " data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000">
        <div className="flex rounded-full outline-dashed outline-white  outline-1 w-20 h-20 place-items-center justify-center">
        <img  src={DigitalMarketingIcon} className="w-10 h-10" />
        </div>

        <label className="font-bold text-md " style={{color:PurpleColor}}>{t("digital-marketing-title")}</label>
        <p className="w-64 text-white text-xs text-center">{t("digital-marketing-desc")}</p>
        </div>


        <div className="flex flex-col place-items-center justify-center gap-1" data-aos="zoom-out" data-aos-delay="200" data-aos-duration="1000">
        <div className="flex rounded-full outline-dashed outline-white  outline-1 w-20 h-20 place-items-center justify-center">
        <img  src={AnimationIcon} className="w-10 h-10" />
        </div>

        <label className="font-bold text-md " style={{color:PurpleColor}}>{t("animation-title")}</label>
        <p className="w-64 text-white text-xs text-center">{t("animation-desc")}</p>
        </div>

        <div className="flex flex-col place-items-center justify-center gap-1" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000">
        <div className="flex rounded-full outline-dashed outline-white  outline-1 w-20 h-20 place-items-center justify-center">
        <img  src={SocialMediaAccountsIcon} className="w-10 h-10" />
        </div>

        <label className="font-bold text-md " style={{color:PurpleColor}}>{t("social-media-accounts-title")}</label>
        <p className="w-64 text-white text-xs text-center">{t("social-media-accounts-desc")}</p>
        </div>

        <div className="flex flex-col place-items-center justify-center gap-1" data-aos="zoom-out" data-aos-delay="200" data-aos-duration="1000">
        <div className="flex rounded-full outline-dashed outline-white  outline-1 w-20 h-20 place-items-center justify-center">
        <img  src={SocialMediaAccountsIcon} className="w-10 h-10" />
        </div>

        <label className="font-bold text-md " style={{color:PurpleColor}}>{t("software-solutions-title")}</label>
        <p className="w-64 text-white text-xs text-center">{t("software-solutions-desc")}</p>
        </div>

        <div className="flex flex-col place-items-center justify-center gap-1" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
        <div className="flex rounded-full outline-dashed outline-white  outline-1 w-20 h-20 place-items-center justify-center">
        <img  src={MarketingModels} className="w-10 h-10" />
        </div>

        <label className="font-bold text-md " style={{color:PurpleColor}}>{t("marketing-models-title")}</label>
        <p className="w-64 text-white text-xs text-center">{t("digital-marketing-desc")}</p>
        </div>


        </div>

        

        </div>
    );
}

export default Services;